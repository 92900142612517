// icons
import React, { useContext } from "react";
import HomeIcon from "@material-ui/icons/Home";
import LoginIcon from "@material-ui/icons/AddToHomeScreenOutlined";
import DashboardIcon from "@material-ui/icons/BarChartOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BankIcon from "@material-ui/icons/AccountTreeOutlined";
import CartIcon from "@material-ui/icons/ShoppingBasketOutlined";
import stellarIcon from "@material-ui/icons/MonetizationOnOutlined";
import WifiIcon from "@material-ui/icons/NetworkWifiRounded";
import UserIcon from "@material-ui/icons/VerifiedUserRounded";

//Authorization
import { useAppSelector } from "../hooks/reduxHooks";

// components
import Home from "../pages/home";
import Dashboard from "../pages/dashboard";

//import BankPage from "../pages/accounts/bankAccount";
import WalletPage from "../pages/accounts/walletsAccounts";
import Settings from "../pages/settings";
import AuthPage from "../pages/authPage";
import stellarPage from "../pages/accounts/stellar";
import InvestPage from "../pages/invest";

// interface
import RouteItem from "./RouteItem.model";
import { selectAuth } from "../components/auth/authSlice";
import { selectStellar } from "../components/stellar/xlmSlice";
// define app routes
export const Loadroutes = () => {
  const authState = useAppSelector(selectAuth);
  const xlmState = useAppSelector(selectStellar);
  const isLoggedIn = authState.isLoggedIn;
  const _tokenTime = authState.remainingTime;
  let routes: Array<RouteItem> = [];
  if (isLoggedIn !== undefined) {
    routes = [
      {
        key: "router-home",
        title: "Home",
        tooltip: "Home",
        path: "/",
        enabled: true,
        component: Home,
        icon: HomeIcon,
        appendDivider: true,
      },
      {
        key: "router-dashboard",
        title: "Cloud",
        tooltip: "Project Cloud",
        path: "/dashboard",
        enabled: isLoggedIn,
        component: Dashboard,
        icon: DashboardIcon,
      },
      {
        key: "router-auth",
        title: "User",
        tooltip: "User Identity",
        path: "/auth",
        enabled: !isLoggedIn,
        component: AuthPage,
        icon: UserIcon,
      },
      {
        key: "router-settings",
        title: "Settings",
        tooltip: "Settings",
        path: "/settings",
        enabled: isLoggedIn,
        component: Settings,
        icon: SettingsIcon,
      },
    ];
  }
  return routes;
};
