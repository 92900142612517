import { useRef, useContext,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//Use Redux hooks to get to see user is logged in and get actions for disptach
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
//Import AuthSlice
import {
  selectAuth
} from '../auth/authSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `3rem auto`,
      width: `95%`,
      maxQidth: `25rem`,
      borderRadius: `6px`,
      // backgroundColor: theme.palette.secondary.main,
      //boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
      padding: `1rem`,
      textAlign: `left`,
    },
    control: {
      marginBottom: `0.5rem`,
    },
    label: {
      display: `block`,
      color: theme.palette.text.secondary,
      fontWeight: `bold`,
      marginBottom: '0.5rem',
    },
    input: {
      borderRadius: `4px`,
      width: `100%`,
      textAlign: `left`,
      padding: theme.spacing(2),
    },
    actions: {
      marginTop: `1.5rem`,
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
    },
    toggle: {
      marginTop: `1rem`,
      backgroundColor: `transparent`,
      color: theme.palette.primary.contrastText,
      border: `none`,
      padding:theme.spacing(1),
    },
  })
);
const ProfileForm: React.FC = (props) => {
  //GetAuth Context
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  //Firebase Rest Method Names
  const apiUpdatePasswordMethodName = "update";
  const passwordInputRef = useRef<HTMLInputElement>(null);
 
  useEffect(() => {
    if (!authState.isAuthLoading  && !authState.isLoggedIn) {
     // let user know the process succeeded by
           //return user to Home
          history.replace('/');

    }else if (!authState.isAuthLoading )
    {
      //TODO add error module for now just use alert
      // show custom error form
    }
  }, [authState, history]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    let enteredPassword ="";
    if(passwordInputRef.current && passwordInputRef.current.value)
    {
      enteredPassword = passwordInputRef.current.value;
    }
    
    console.log(`Your Password has been changed`)
  };
  return (
    <form onSubmit ={submitHandler} className={classes.root}>
      <CardContent className={classes.control}>
        <label className={classes.label} htmlFor='new-password'>New Password</label>
        <input className={classes.input} ref={passwordInputRef} type='password' id='new-password' />
      </CardContent>
      <CardActions className={classes.actions}>
        <Button type="submit"
                        variant="contained"
                        color="inherit">Change Password</Button>
      </CardActions>
    </form>
  );
}

export default ProfileForm;
