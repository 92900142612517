import React from 'react';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ProfileForm from './profileForm';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { PAGE_WIDTH } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4),
      width: PAGE_WIDTH,
      maxQidth: `25rem`,
      borderRadius: `6px`,
      // backgroundColor: theme.palette.secondary.main,
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      textAlign: `left`,
    },
  })
);
const UserProfile = () => {
  const classes = useStyles();
  const Title = "Profile Page";
  const subTitle ="Welcome Back!";
  return (
    <Card title={Title}  className={classes.root}>

      <ProfileForm />
    </Card>
  );
};

export default UserProfile;
