import React from "react";
import PropTypes from "prop-types";
import {createTheme,withStyles,Theme,ThemeOptions} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import ThemeColors from "./customColors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import cryoRIOLightPrimaryColor  from "./cryoRIOColors";
import { constant } from "lodash-es";




const styles = {
    root: {
    display: "flex",
    },
};
//Use our theme class to get our saved colors
const themeColors = new ThemeColors();
//Lght Theme
const lightPrimary = themeColors.getLightColors().primary;
const lightSecondary = themeColors.getLightColors().secondary;
const lightAccent = themeColors.getLightColors().accent;


// define light theme colors
export const lightTheme: Theme = createTheme({
  palette: {
    type: "light",
    //dark blue
    primary: {
      main: lightPrimary[900],
    },
    //light blue
    secondary: {
      main: lightSecondary[500],
    },
    info: {
      main: red[200],
    },
    warning: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: "Leelawadee UI",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontFamily: "Leelawadee UI",
      fontWeight: 500,
    },
    body2: {
      fontFamily: "Leelawadee UI",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
      fontFamily: "Airel",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url(${process.env.PUBLIC_URL}/backgrounds/skynet.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      },
    },
  },
});


//Define Dark Theme Colors
const darkPrimary = themeColors.getDarkColors().primary;
const darkSecondary = themeColors.getLightColors().secondary;
const darkAccent = themeColors.getLightColors().accent;
// define dark theme colors
export const darkTheme: Theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: darkPrimary[500],
    },
    secondary: {
      main: darkSecondary[400],
    },
    info: {
      main: darkAccent[700],
    },
    warning: {
      main: red[400],
    },
  },
  typography: {
    fontFamily: "Leelawadee UI",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
    },
  },

});