import React, { Fragment, ReactElement,useEffect, useReducer, FC } from "react";
import { useAppSelector, useAppDispatch } from "./hooks/reduxHooks";

import {
  createTheme,
  Theme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    selectUI,
} from "./components/ui/uiSlice";

import {
  authActions,
  selectAuth,
} from "./components/auth/authSlice";
import Toast from "./components/ui/toast";

// components
import Layout from "./components/ui/layout";
import LayoutSideView from "./components/ui/layoutSideView";

// theme
import { lightTheme, darkTheme } from "./theme/appTheme";

// app routes
import { Loadroutes } from "./config";

// constants
import { APP_TITLE } from "./helpers/constants";
import { ethers } from "ethers";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
// interfaces
import RouteItem from "./config/RouteItem.model";
import { ToastItem } from "./components/ui/uiModels";
import { selectStellar } from "./components/stellar/xlmSlice";

//define timer for user log in
// define app context
const AppContext = React.createContext(null);

// default component
const DefaultComponent: FC<{}> = (): ReactElement => (
  <div>{`No Component Defined.`}</div>
);

//get ether network
function getLibrary(provider: any): ethers.providers.Web3Provider {
  const library = new ethers.providers.Web3Provider(provider);

  //Setup Connects and state
  library.detectNetwork();
  library.pollingInterval = 12000;
  return library;
}

function
App() {
  const [useDefaultTheme, toggle] = useReducer((theme) => !theme, true);
  //tracks first time load on a machine
  let isInitial = true;
  const uiState = useAppSelector(selectUI);
  const authState = useAppSelector(selectAuth);
  const xmlState = useAppSelector(selectStellar);
  const dispatch = useAppDispatch();

  let logoutTimer: NodeJS.Timeout;
  // define custom theme
  let theme: Theme = createTheme(useDefaultTheme ? lightTheme : darkTheme);
  theme = responsiveFontSizes(theme);
  let routes = Loadroutes();
  const createTimer = (remainingTime: number) => {
    return setTimeout(() => dispatch(authActions.logout()), remainingTime);
  };


//This controls the auth state of the user
  useEffect(() => {
   // if (isInitial) {
   //   isInitial = false;
   //   if (authState.isLoggedIn) {
   //     logoutTimer = createTimer(authState.remainingTime);
   //   }
   // }
    if (authState.isLoggedIn) {
      //Bug with getting remainingTime from storage location
      createTimer(authState.remainingTime);
    }
    //Setup timer for stellar
    if (xmlState.isVaildAccount) {
      //Bug with getting remainingTime from storage location
      createTimer(xmlState.remainingTime);
    }
    if (authState.isLoggedIn === false && logoutTimer !== undefined) {
      //Clear logout timer when users logs out
      return clearTimeout(logoutTimer);
    }
    //dispatch(sendCartData(cart));
  }, [uiState, authState,xmlState, useAppDispatch, isInitial]);

  let toastMessageState: ToastItem = {
    status: uiState.notification.status,
    message: uiState.notification.message,
  };


  return (
    <>
      <AppContext.Provider value={null}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Helmet>
            <title>{APP_TITLE}</title>
          </Helmet>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Fragment>
                  {uiState.notification &&
                    uiState.notification.status !== "Idel" && (
                      <Toast toastMessage={toastMessageState} />
                    )}
                  <Layout
                    toggleTheme={toggle}
                    useDefaultTheme={useDefaultTheme}
                  >
                    {/* for each route config, a react route is created */}
                    {routes.map((route: RouteItem) =>
                      route.subRoutes ? (
                        route.subRoutes.map((item: RouteItem) => (
                          <Route
                            key={`${item.key}`}
                            path={`${item.path}`}
                            component={item.component || DefaultComponent}
                            exact
                          />
                        ))
                      ) : (
                        <Route
                          key={`${route.key}`}
                          path={`${route.path}`}
                          component={route.component || DefaultComponent}
                          exact
                        />
                      )
                    )}
                  </Layout>
                </Fragment>
              </Switch>
            </Router>
          </ThemeProvider>
        </Web3ReactProvider>
      </AppContext.Provider>
    </>
  );
}

export default App;
