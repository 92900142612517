import { Email } from '@material-ui/icons';
import { createSlice, PayloadAction} from '@reduxjs/toolkit';
//look in to ThunkDispatch;
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks";
import { RootState, AppThunk } from '../../appStore/store';

import {LoadFirebaseLoginDataAsync} from "./authFireBaseAPI";
import { AuthTokenState, FB_AuthLoginResponse, FB_User, LoginProviders } from "./authModels";


const tokenFieldKey = 'idToken';
const expriresTimeKey = 'expiresIn';
const logInProviderKey = "logInProvider";

const calRemaingTime = (tokenexpiresIn:string):number =>{
    const currentTime = new Date().getTime();
    const adjTime = new Date(tokenexpiresIn).getTime();
    return (adjTime - currentTime);

};

//Create the initialAuthState for Authentication
const getInitialAuthState = (): AuthTokenState => {
  let tokenValue = localStorage.getItem(tokenFieldKey);
  let remainingTimeVale = localStorage.getItem(expriresTimeKey);
  let loginProviderValue = localStorage.getItem(logInProviderKey);
  let LoggedIn = tokenValue !== null ? true : false;
  let token: string = tokenValue !== null ? tokenValue : "";
  let user: FB_User = {
    localid: "",
    email: "",
    photo: "",
  };
  let remainingTime: number =
    remainingTimeVale !== null ? calRemaingTime(remainingTimeVale) : 0;
  let loginProvider = loginProviderValue ? loginProviderValue : "Firebase";

  return {
    token: token,
    isLoggedIn: LoggedIn,
    remainingTime: remainingTime,
    isAuthLoading: false,
    provider: "Firebase",
    user: user,
  };
}; ;





//Api calls use the thunk
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const ExampleThunk = (amount: number): AppThunk => (
    dispatch,
    getState
) => {
   // const currentValue = selectCount(getState());
   // if (currentValue % 2 === 1) {
   //   dispatch(incrementByAmount(amount));
    //}
};
//Create AuthSlice using the reduxjs/toolkit createSlice method

export const authSlice = createSlice({
  name: "authentication",
  initialState: getInitialAuthState(),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    replaceLoginData: (state, action: PayloadAction<AuthTokenState>) => {
      state = action.payload;
      let tr = calRemaingTime(action.payload.remainingTime.toString());
      localStorage.setItem(tokenFieldKey, action.payload.token);
      localStorage.setItem(
        expriresTimeKey,
        action.payload.remainingTime.toString()
      );
      if (action.payload.token) {
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.remainingTime = tr;
      }
    },
    setFBLoginData: (state, action: PayloadAction<FB_AuthLoginResponse>) => {
      //Here we set the AuthTokenState
      state.isAuthLoading = false;
      //Set time remaining value
      let tr = calRemaingTime(action.payload.expiresIn);
      //Log user out when Timer expires
      // setTimeout(authSlice.actions.logout, tr);
      if (action.payload.idToken) {
        state.token = action.payload.idToken;
        state.isLoggedIn = true;
        state.remainingTime = tr;
      }
    },
   saveLogoutTimer: (state) => {},

    logout: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem(tokenFieldKey);
      localStorage.removeItem(expriresTimeKey);
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(LoadFirebaseLoginDataAsync.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(LoadFirebaseLoginDataAsync.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        if (action.payload.data.idToken === "") {
          state.isLoggedIn = false;
        } else {
          //Set time remaining value
          let remainingTimeValue = new Date(
            new Date().getTime() + +action.payload.data.expiresIn * 1000
          );
          let tr = calRemaingTime(remainingTimeValue.toString());
          localStorage.setItem(tokenFieldKey, action.payload.data.idToken);
          localStorage.setItem(expriresTimeKey, tr.toString());
          if (action.payload.data.idToken) {
            state.token = action.payload.data.idToken;
            state.isLoggedIn = true;
            state.remainingTime = tr;
          }
          if (action.payload.data.localId) {
            state.user.localid = action.payload.data.localId;
          }
        }
      });
  },
});

//export methods needed for components
export const authActions = authSlice.actions;

//export State interface
export const selectAuth = (state: RootState) => state.auth;



export default authSlice.reducer;