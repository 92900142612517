import { createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { uiActions } from "../../components/ui/uiSlice";
import {
  BankAccountState,
  BankAccountProviders,
} from "./wallets/accountModels";


import { RootState, AppThunk } from "../../appStore/store";

import { PlaidAccountInfo } from "./providers/plaidModels";


//Plaid provider


const initialBankAccountState: BankAccountState = {
  isLoaded: false,
  provider: BankAccountProviders[0],
  providerToken:"",
};

//This is used for the Plaid provider to get create a link token for the user
//It takes the userId that is used to save the plaidToken and BankInfo after vaildation
export const GetPlaidLinkToken = createAsyncThunk(
  "api/user/accounts/bank/plaid/getlinktoken",
  async (userID: string, thunkAPI) => {
    //set initstate for respn
    let response:string ='';
    console.log("Getting Plaid token");
    thunkAPI.dispatch(
      uiActions.showToast({
        status: "loading",
        message: "Getting Plaid token",
      })
    );
      async function createLinkToken() {
        let urlPath: string = `http://localhost:2709/create-link-token?userid=${userID}`;
        let resp = await fetch(urlPath);
        const token  = await resp.json();
        console.log(token);
        return token;
      }
    // The value we return becomes the `fulfilled` action payload
    //So here we need to do other Login Task

    try {
      thunkAPI.dispatch(uiActions.updateToastMessage("Got the Plaid token!"));
      thunkAPI.dispatch(uiActions.hideToast());
      //Dispach Login
      response = await createLinkToken(); 
      //response = {  };
    } catch {
      //Disptach error message
    }

    return response;
  }
);
export const PlaidTokenExchange = createAsyncThunk(
  "api/user/accounts/bank/plaid/token-exchange",
  async (publicToken: string, thunkAPI) => {
    //set initstate for respn
    let response: string = "";
    console.log("Getting Plaid token");
    thunkAPI.dispatch(
      uiActions.showToast({
        status: "loading",
        message: "Getting Plaid token",
      })
    );
    async function tokenExchange() {
      let urlPath: string = `http://localhost:2709/token-exchange?publictoken=${publicToken}`;
      let resp = await fetch(urlPath);
      const token = await resp.json();
      console.log(token);
      return token;
    }
    // The value we return becomes the `fulfilled` action payload
    //So here we need to do other Login Task

    try {
      thunkAPI.dispatch(uiActions.updateToastMessage("Exchange the Plaid token!"));
      thunkAPI.dispatch(uiActions.hideToast());
      //Dispach Login
      response = await tokenExchange();
      //response = {  };
    } catch {
      //Disptach error message
    }

    return response;
  }
);

export const GetBankAccountInfo = createAsyncThunk(
  "api/user/accounts/bank/plaid",
  async (usePlaid:boolean, thunkAPI) => {
    //set initstate for respn
    let response: PlaidAccountInfo = {
      usePlaid: usePlaid,
    };

    thunkAPI.dispatch(
      uiActions.showToast({
        status: "loading",
        message: "Trying to log you in.",
      })
    );
    
    // The value we return becomes the `fulfilled` action payload
    //So here we need to do other Login Task

    try {
      thunkAPI.dispatch(uiActions.updateToastMessage("Great You made it!"));
      thunkAPI.dispatch(uiActions.hideToast());
      //Dispach Login
      //response = {  };
    } catch {
      //Disptach error message
    }

    return response;
  }
);

const bankAccountSlice = createSlice({
  name: "ui",
  initialState: initialBankAccountState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(GetBankAccountInfo.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(GetBankAccountInfo.fulfilled, (state, action) => {
        state.isLoaded = true;
       
        //load bankAccount into state from payload
      })
       .addCase(GetPlaidLinkToken.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(GetPlaidLinkToken.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.providerToken = action.payload;
        //load bankAccount into state from payload
      });
  },
});

//export State interface
export const selectBankAccount = (state: RootState) => state.userBankAccounts;

export const bankActions = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
