import React from 'react';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import CarIcon from '@material-ui/icons/CardGiftcardRounded';
//import GenieIcon from "../../images/MairoGenie.svg";
import P5Sketch from "../ui/p5Sketch"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 440,
    },
    svgBackgroundContainer: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/backgrounds/skynet.svg)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      // Add other styling as needed
      width: "100%", // Example width
      height: "500px", // Example height
    },
  })
);

const StartingPageContent = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Container className={classes.paper}>
      <img
        src={process.env.PUBLIC_URL + "/logos/fieldPro360Logo.png"}
        alt="Splash Image"
      />
    </Container>
  );
};

export default StartingPageContent;
