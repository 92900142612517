import { createAsyncThunk } from "@reduxjs/toolkit";
import { StellarAccountReq, XLM_AccountResponse } from "./xlmModels";
import { uiActions } from "../ui/uiSlice";
import { xlmTestServer } from "./providers/xlmConnectors";
import { AccountResponse, Keypair, Horizon } from "stellar-sdk";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const LoadAccountAsync = createAsyncThunk(
  "api/stellar/load",
  async (loginParams: StellarAccountReq, thunkAPI) => {
    //set initstate for respn
    let response: XLM_AccountResponse = {
      mainKey: undefined,
      account: undefined,
      friendKey: undefined,
      payment: undefined,
      error: undefined,
      expiresIn: "600000",
      localId: "",
      registered: false,
    };

    thunkAPI.dispatch(
      uiActions.showToast({
        status: "loading",
        message: "Trying to log you in.",
      })
    );

    try {
      response.mainKey = Keypair.fromSecret(loginParams.secret);
      let account: AccountResponse = await xlmTestServer.loadAccount(
        response.mainKey.publicKey()
      );

      //convert to type
      if (account.account_id) {
        thunkAPI.dispatch(
          uiActions.updateToastMessage("Account Loaded and Ready!")
        );
        thunkAPI.dispatch(uiActions.hideToast());
        //Dispach Login
        response.account = account;
        //Change the Random once you understand what friend is used for
        response.friendKey = Keypair.random();
        response.localId = account.account_id;
        //Here we create payment rails
        // setTimeout(authSlice.actions.logout, 3000);
      } else {
        response.error = new Error("Stellar SDK failed!");
        thunkAPI.dispatch(uiActions.updateToastMessage(""));
        thunkAPI.dispatch(
          uiActions.showToast({
            status: "error",
            message: "Try again Buster!.",
          })
        );
      }
    } catch {
      //Disptach error message
    }
    return response;
  }
);
