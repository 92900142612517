import React, { FC, ReactNode, useReducer } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// components
import Header from "./header";
import Navigation from "./navigation";
import Footer from "./footer";
//Move out to button
import { authActions } from "../../components/auth/authSlice";
// constants
import { DRAWER_WIDTH, FOOTER_HEIGHT } from "../../helpers/constants";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { FB_AuthLoginResponse } from "../auth/authModels";
// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      background: theme.palette.background.paper,
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: DRAWER_WIDTH,
    },
  })
);

// define interface to represent component props
interface Props {
  toggleTheme: () => void;
  useDefaultTheme: boolean;
  children: ReactNode;
}

// functional component
const Layout: FC<Props> = ({ toggleTheme, useDefaultTheme, children }) => {
  const classes = useStyles();
  const [open, toggle] = useReducer((open) => !open, true);
  const history = useHistory();

  const dispatch = useAppDispatch();

  //useEffect for web3 Ethers state set up
  const [activatingConnector, setActivatingConnector] = React.useState<any>();


  //Here are the different providers Handels this does the dispatching
  const connectFirebaseHandler = async () => {
    try {
      //route to user page
      //dispatch(authActions.setFBLoginData());
      history.replace("/auth");
    } catch (ex) {
      console.log(ex);
    }
  };


  const logoutHandler = () => {
    dispatch(authActions.logout());
    history.replace("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        open={open}
        handleMenuOpen={toggle}
        handleSignIn={connectFirebaseHandler}
        handleSignUp={connectFirebaseHandler}
        handleDisconnect={logoutHandler}
        toggleTheme={toggleTheme}
        useDefaultTheme={useDefaultTheme}
      />
      <Navigation open={open} handleMenuClose={toggle} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
