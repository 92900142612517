// Move to Slice or API Interface for now I put everything in one file
//this creates a connection to the horizon test server in future get config setting from .env file
//this needs to have its on state component
//Also it might already be set with rabit wallet this is why this needs to move out top app level

import { property } from "lodash-es";
import { Rabit_ConnectResult } from "../xlmModels";
import {
  Keypair,
  Server,
  AccountResponse,
  TransactionBuilder,
  BASE_FEE,
  Networks,
  Operation,
  Asset,
  Horizon,
} from "stellar-sdk";
import { domainToASCII } from "url";

//Make this a class wrapper and setup settings for our Network
//use this to connect to test stellar network
export const xlmTestServer: Server = new Server(
  "https://horizon-testnet.stellar.org"
);

export const xlmLocalTestServer: Server = new Server(
  "https://horizon-testnet.stellar.org"
);

export const xlmCryoRIOServer: Server = new Server(
  "https://horizon-testnet.stellar.org"
);

export const xlmPUBLICNetwork = Networks.PUBLIC;
export const networkPassphrase = Networks.TESTNET;
//This repersents the fee for the Network
export const xlmNetworkFee = BASE_FEE;
export const xlmTransactionBuilder = TransactionBuilder;
export const xlmKeypair = Keypair;

export interface IStellarBaseProcessService {
  CreateLoad: () => {};
  fund: () => {};
  update: () => {};
  pay: () => {};
}

export class xlmStateServer {
  private server: Server;
  private linkedKey?: string;
  public mainKey: Keypair;
  public account?: AccountResponse;
  public friendKey: Keypair;
  private payment?: Horizon.SubmitTransactionResponse;
  private error?: Error;

  constructor(
    url: string,
    key: string,
    secrectPhrase: boolean = false,
    passPhrase: boolean = false
  ) {
    if (url !== undefined) {
      this.server = new Server(url);
    } else {
      this.server = xlmTestServer;
    }
    //Create a key for account if not given one
    if (key !== undefined && secrectPhrase === true && passPhrase === false) {
      this.mainKey = Keypair.fromSecret(key);
    } else if (
      key !== undefined &&
      secrectPhrase === false &&
      passPhrase === false
    ) {
      this.linkedKey = key;
      this.mainKey = Keypair.random();
      this.friendKey = Keypair.random();
    } //Add passpharse code
    else {
      this.mainKey = Keypair.random();
      this.friendKey = Keypair.random();
    }
    this.friendKey = Keypair.random();
    // this.account = this.accountCreate();
  }

  // METHODS-- add async

  accountLoad() {
    if (this.linkedKey !== undefined)
    {
      return this.server.loadAccount(this.linkedKey);
    }
    else
    {
      return this.server.loadAccount(this.mainKey.publicKey());
    }

  }
  accountFund() {
    return Keypair.random();
  }
  accountUpdate() {
    return Keypair.random();
  }
  accountPay() {
    return Keypair.random();
  }
  //Fund Account

  //Create a Trust Line to CPT
  // --- END METHODS

  return: IStellarBaseProcessService = {
    fund: this.accountFund,
    update: this.accountUpdate,
    CreateLoad: this.accountLoad,
    pay: this.accountPay,
  };
}

//Here we create an instance of the xml Test Server as default
//In our main react app we will need to create the server with the correct URL and Accounts
//export const XlmTestStateServer = new xlmStateServer("https://horizon-testnet.stellar.org","SCLWJEX4RHBBVKTOR2SS7YPP3MPCAREEMFT5DGDAR5HKAZH75AEZYV3X",false);

