import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const LoadingIndicator = () => (
  <CircularProgress color="secondary" />

);

export default LoadingIndicator;
