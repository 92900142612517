import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
//import AuthForm from '../components/Auth/AuthForm';
import AuthForm from '../components/auth/authForm';

import clsx from 'clsx';
import { APP_TITLE, PAGE_TITLE_AUTH } from '../helpers/constants';
import PageTitle from "../components/ui/pageTitle";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
    })
);
const AuthPage: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
      <>
          <Helmet>
              <title>{} | {APP_TITLE}</title>
          </Helmet>
          <div className={classes.root}>
            <PageTitle title={PAGE_TITLE_AUTH} />
            </div>
            <AuthForm />;
        </> )
};

export default AuthPage;
