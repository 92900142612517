import { Email } from "@material-ui/icons";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//look in to ThunkDispatch;
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks";
import { RootState, AppThunk } from "../../appStore/store";
import { StellarWalletState, StellarWalletProviders, Rabit_ConnectResult }  from "./xlmModels";
import { LoadAccountAsync } from "./stellarAPI";
import { Keypair, AccountResponse, BASE_FEE, Networks } from "stellar-sdk";

const tokenFieldKey = "idStellar";
const expriresTimeKey = "xlmExpiresIn";
const stellarProviderKey = "stellarProvider";

const calRemaingTime = (tokenexpiresIn: string): number => {
  const currentTime = new Date().getTime();
  const adjTime = new Date(tokenexpiresIn).getTime();
  return adjTime - currentTime;
};

//Create the initial Stellar state
const getInitialWalletState = (): StellarWalletState => {
  let tokenValue = localStorage.getItem(tokenFieldKey);
  let remainingTimeVale = localStorage.getItem(expriresTimeKey);
  let loginProviderValue = localStorage.getItem(stellarProviderKey);
  let VaildAccount = tokenValue !== null ? true : false;
  let token: string = tokenValue !== null ? tokenValue : "";
  let remainingTime: number =
    remainingTimeVale !== null ? calRemaingTime(remainingTimeVale) : 0;
  let stellarWalletProvider = loginProviderValue
    ? loginProviderValue
    : "SECRET_KEY";

  return {
    accountCode: token,
    isVaildAccount: VaildAccount,
    remainingTime: remainingTime,
    isAuthLoading: false,
    provider: "SECRET_KEY",
  };
};

export const xlmSlice = createSlice({
  name: "stellar_wallet",
  initialState: getInitialWalletState(),
  // The `reducers` field lets us define reducers and generate associated actions
  //Here we create the end points for ui to talk to back end
  reducers: {
    replaceLoginData: (state, action: PayloadAction<StellarWalletState>) => {
      state = action.payload;
      let tr = calRemaingTime(action.payload.remainingTime.toString());
      localStorage.setItem(tokenFieldKey, action.payload.accountCode);
      localStorage.setItem(
        expriresTimeKey,
        action.payload.remainingTime.toString()
      );
      if (action.payload.accountCode) {
        state.accountCode = action.payload.accountCode;
        state.isVaildAccount = true;
        state.remainingTime = tr;
      }
    },
    connectRabetAccount: (
      state,
      action: PayloadAction<Rabit_ConnectResult>
    ) => {
      //Here we set the AuthTokenState
      state.isAuthLoading = false;
      //Make logins last for one hour before need to reconnect
      if (action.payload.publicKey) {
        state.accountCode = action.payload.publicKey;
        state.isVaildAccount = true;
        state.remainingTime = 60000;
      }
    },
    loadStellarAccount: (
      state,
      action: PayloadAction<AccountResponse>
    ) => {
      //Here we set the AuthTokenState
      state.isAuthLoading = false;
      //Make logins last for one hour before need to reconnect
      if (action.payload) {
        state.accountResponse = action.payload;
        state.isVaildAccount = true;
        state.remainingTime = 60000;
      }
    },
    saveLogoutTimer: (state) => {},

    logout: (state) => {
      state.isVaildAccount = false;
      localStorage.removeItem(tokenFieldKey);
      localStorage.removeItem(expriresTimeKey);
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(LoadAccountAsync.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(LoadAccountAsync.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        if (action.payload.account === undefined) {
          state.isVaildAccount = false;
        } else {
          //Set time remaining value
          let remainingTimeValue = new Date(
            new Date().getTime() + +action.payload.expiresIn * 1000
          );
          let tr = calRemaingTime(remainingTimeValue.toString());

          if (action.payload.localId) {
            localStorage.setItem(tokenFieldKey, action.payload.localId);
            localStorage.setItem(expriresTimeKey, tr.toString());
            state.accountCode = action.payload.localId;
            state.isVaildAccount = true;
            state.remainingTime = tr;
          }
        }
      });
  },
});

//export methods needed for components
export const xmlActions = xlmSlice.actions;

//export State interface
export const selectStellar = (state: RootState) => state.stellar;

export default xlmSlice.reducer;
