//Make this a json file 
// APP TEXT
export const APP_TITLE = "Field Pro360";
export const FOOTER_TEXT = `${new Date().getFullYear()} @Skylynx Network`;

// PAGES TITLE
export const PAGE_TITLE_HOME = "Home";
export const PAGE_TITLE_AUTH = "Login/Signup";
export const PAGE_TITLE_AUTH_SIGNUP = "Signup";
export const PAGE_TITLE_AUTH_LOGIN = "Login";
export const PAGE_TITLE_DASHBOARD = "Cloud";
export const PAGE_TITLE_ACCOUNTS = "Users";
export const SUBPAGE_TITLE_BANK = "Projects";
export const SUBPAGE_TITLE_ETH = "Network";
export const SUBPAGE_TITLE_XLM = "Wallet";
export const PAGE_TITLE_SETTINGS = "Settings";
// UI CONSTANTS
export const Sketch_HEIGHT = 300;
export const Sketch_WIDTH = 500;
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
export const PAGE_WIDTH = 500;
//Error Window CONSTANTS
export const ERRORWIN_TOP = `30vh`;
export const ERRORWIN_LEFT = `15rem`;
export const ERRORWIN_WIDTH = `30rem`;
//Auth Provider and Database keys comes from .env
export const FIREBASE_APIKEY = "AIzaSyCcTBfQd2kWrn-Cd9JFdKkf6Xtqmw2czbA";
export const PLAID_CLIENT_ID="608336c497bb9a00116921b3;"
export const PLAID_SECRET="4666cc684b82d97367074ff2520b1e";
export const Stellar_IssuerAct =  "GBW3KKPZJXDWIQKMN6ZTM4PFK5K5NSAREQHIBWAYT6BRAFF6OTDF754N";
export const Stellar_DistAct =  "GDA5NSJ5AA4SD7DQ32ZE3XNRN5EDHW6P26OYRCZSUXWA5OJ6PABBAOEA";
export const CPT_Act = "";
export const StellarAnchorClient_SKEY = "";