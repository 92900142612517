export default class ThemeColors {
  Light = {
    primary: {
      50: "#E3F2FD",
      100: "#BBDEFB",
      200: "#90CAF9",
      300: "#64B5F6",
      400: "#42A5F5",
      500: "#2196F3",
      600: "#1E88E5",
      700: "#1976D2",
      800: "#1565C0",
      900: "#0D47A1",
      A100: "#83C1F3",
      A200: "#3061AC",
      A400: "#1981D9",
      A700: "#1963A2",
    },
    secondary: {
      50: "#F9F99A",
      100: "#F9F98E",
      200: "#FFFF6B",
      300: "#F6F654",
      400: "#FFFF1A",
      500: "#EFEF12",
      600: "#DBDB14",
      700: "#CECE19",
      800: "#B8B813",
      900: "#A1A10D",
      A100: "#EFEF27",
      A200: "#E1E125",
      A400: "#B7B71A",
      A700: "#7F7F00",
    },
    accent: {
      50: "#D0DAE9",
      100: "#D0DAE9",
      200: "#BEC9D9",
      300: "#A7AFBC",
      400: "#949DA9",
      500: "#8993A1",
      600: "#7F8691",
      700: "#79808A",
      800: "#6E747E",
      900: "#5E646D",
      A100: "#859ABC",
      A200: "#6E6F71",
      A400: "#535354",
      A700: "#31353A",
    },
  };

  Dark = {
    primary: {
      50: "#E3F2FD",
      100: "#BBDEFB",
      200: "#90CAF9",
      300: "#64B5F6",
      400: "#42A5F5",
      500: "#2196F3",
      600: "#1E88E5",
      700: "#1976D2",
      800: "#1565C0",
      900: "#0D47A1",
      A100: "#83C1F3",
      A200: "#3061AC",
      A400: "#1981D9",
      A700: "#1963A2",
    },
    secondary: {
      50: "#F9F99A",
      100: "#F9F98E",
      200: "#FFFF6B",
      300: "#F6F654",
      400: "#FFFF1A",
      500: "#EFEF12",
      600: "#DBDB14",
      700: "#CECE19",
      800: "#B8B813",
      900: "#A1A10D",
      A100: "#EFEF27",
      A200: "#E1E125",
      A400: "#B7B71A",
      A700: "#7F7F00",
    },
    accent: {
      50: "#D0DAE9",
      100: "#D0DAE9",
      200: "#BEC9D9",
      300: "#A7AFBC",
      400: "#949DA9",
      500: "#8993A1",
      600: "#7F8691",
      700: "#79808A",
      800: "#6E747E",
      900: "#5E646D",
      A100: "#859ABC",
      A200: "#6E6F71",
      A400: "#535354",
      A700: "#31353A",
    },
  };
  // Expose Light and Dark properties
  getLightColors() {
    return this.Light;
  }

  getDarkColors() {
    return this.Dark;
  }
}
