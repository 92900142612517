import { useRef, useContext,useEffect} from 'react';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {ToastItem} from "./uiModels";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    toast: {
    margin: `3rem auto`,
    width: `100%`,
    maxQidth: `25rem`,
    borderRadius: `6px`,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
    padding: `1rem`,
    textAlign: `left`,
    },
    error: {
    display: `block`,
    color: theme.palette.text.secondary,
    fontWeight: `bold`,
    marginBottom: '0.5rem',
    },
    success : {
    marginTop: `1.5rem`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    },
})
);

const Toasted: React.FC<{toastMessage:ToastItem}> = (props) => {
  const classes = useStyles();

  let statusClasses = "";

    if (props.toastMessage.status === 'error') {
    statusClasses = classes.error;
    }
    if (props.toastMessage.status === "success") {
      statusClasses = classes.success;
    }
    if (props.toastMessage.status === "loading") {
      statusClasses = classes.success;
    }
    if (props.toastMessage.status === "Idel") {
      statusClasses = classes.success;
    }

    const cssClasses = `${classes.toast} ${statusClasses}`;

    return (
      <section className={cssClasses}>
        <h2>{props.toastMessage.message}</h2>
      </section>
    );
};

export default Toasted;